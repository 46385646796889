import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import Intro from "../components/page-intro"
import CenteredCTA from "../components/cta--centered"
import H4 from "../components/Utility/heading4"

export default class FaqPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Proteins FAQs</title>
          <meta
            name="description"
            content="Get answers to frequently asked questions on blended meats and alternative, plant based proteins, and learn how to incorporate Raised & Rooted™ products in your diet."
          />
        </Helmet>
        <Layout>
          <Intro
            bgImage="/illustrated-background.svg"
            headingText1="Plant Based Proteins FAQs"
            headingColor1="#006B2D"
            bodyColor="#006B2D"
            bodyText="What’s in Raised & Rooted&trade; products? How are they made? Are they nutritious? How do they taste? You’ve got questions, we’ve got answers."
          />
          <ul class="faqs">
            <li>
              <H4 headingText1="WHAT IS RAISED & ROOTED?" />
              <p>
                Raised & Rooted was created to raise taste expectations of plant
                based foods with products that are rooted in how people eat
                today. Our products are for people who want to make more
                nutritious choices—but enjoy the same meaty foods they love. How
                do we do it? We take your favorite foods and re-create them with
                the nutritional power of plants—making them more wholesome and
                more flavorful than you thought possible.
              </p>
            </li>
            <li>
              <H4 headingText1="What is meatless meat made of?" />
              <p>
                Our plant based protein substitutes for meat are made from pea
                protein isolate.
              </p>
            </li>
            <li>
              <H4 headingText1="What is pea protein isolate?" />
              <p>
                Pea protein isolate is a healthy meat alternative made from
                yellow split peas. Compared to pea protein concentrate, it is
                denser in protein and a great source of fiber.
              </p>
            </li>
            <li>
              <H4 headingText1="Is pea protein healthy?" />
              <p>
                Short answer? Definitely. Pea proteins contain all the essential
                amino acids, and appeal especially to those who have an
                intolerance to lactose. It is relatively low in fat compared to
                more traditional sources, too.
              </p>
            </li>
            <li>
              <H4 headingText1="What are vegetarian nuggets made of?" />
              <p>
                Taste, that’s what. Our{" "}
                <a href="https://test.raisedandrooted.com/products/plant-based-nuggets/">
                  plant-based nuggets
                </a>{" "}
                are made with pea protein isolate and golden flax seed. It all
                comes together to form one delicious bite.
              </p>
            </li>
            {/*<li>
              <H4 headingText="What’s in the Buffalo Style Plant Based Bites?" />
              <p>
              For starters, <a href="https://www.raisedandrooted.com/products/">Buffalo Style Plant Based Bites</a> 33% less saturated fat, 8 grams of protein, cayenne pepper and a whole lot of flavor. Seriously, all you have to prepare is your fork.
              </p>
            </li>
            <li>
              <H4 headingText1="What’s in the Sweet Barbeque Plant Based Bites?" />
              <p>
              Let’s just say they’re barbeque bites done right. <a href="https://www.raisedandrooted.com/products/">Sweet Barbeque Plant Based Bites</a> 8 grams of protein, 3 grams of fiber and a mouthwateringly sweet and smoky glaze. Simply saucy.
              </p>
            </li>*/}
            <li>
              <H4 headingText1="What’s in the Buffalo Style Plant Based Bites?" />
              <p>
                For starters,{" "}
                <a href="https://test.raisedandrooted.com/products/buffalo-style-plant-based-bites/">
                  Buffalo Style Plant Based Bites
                </a>{" "}
                have 33% less saturated fat, 8 grams of protein, cayenne pepper
                and a whole lot of flavor. Seriously, all you have to prepare is
                your fork.
              </p>
            </li>
            <li>
              <H4 headingText1="What’s in the Sweet Barbecue Plant Based Bites?" />
              <p>
                Let’s just say they’re barbeque bites done right.{" "}
                <a href="https://test.raisedandrooted.com/products/sweet-barbecue-plant-based-bites/">
                  Sweet Barbecue Plant Based Bites
                </a>{" "}
                have 8 grams of protein, 3 grams of fiber and a mouthwateringly
                sweet and smoky glaze. Simply saucy.
              </p>
            </li>
            <li>
              <H4 headingText1="What are Plant Based Burgers made of?" />
              <p>
                Well, why don’t we start with what isn’t included. Our{" "}
                <a href="https://test.raisedandrooted.com/products/plant-based-burger">
                  plant based burger
                </a>{" "}
                have 75% less saturated fat* in each serving, yet we’ve squeezed
                in 21 grams of protein.
              </p>
            </li>
            <li>
              <H4 headingText1="Are plant based burgers healthy?" />
              <p>
                Well, this particular{" "}
                <a href="https://test.raisedandrooted.com/products/plant-based-burger">
                  Plant Based Burger
                </a>{" "}
                is the best soy-free meat substitute. And reducing meat
                consumption has real environmental benefits, too. So, we like to
                call it the only burger that’s guilt-free.
              </p>
              <br></br>
              <p>*Compared to USDA 80% lean/20% fat beef.</p>
            </li>
            <li>
              <H4 headingText1="What’s in the Bratwurst Style Plant Based Sausage?" />
              <p>
                The desire to fire up the grill.{" "}
                <a href="https://test.raisedandrooted.com/products/plant-based-bratwurst">
                  Bratwurst Style Plant Based Sausage
                </a>{" "}
                is made from juicy plant protein and 75% less saturated fat*,
                you can enjoy on a warm toasty bun or savor in this flavor all
                on its own. Mmm.
              </p>
              <br></br>
              <p>*Compared to USDA Italian sausage, pork.</p>
            </li>
            <li>
              <H4 headingText1="What’s in the Plant Based Ground?" />
              <p>
                Ground breaking flavor. Ha. But really,{" "}
                <a href="https://test.raisedandrooted.com/products/plant-based-ground-beef">
                  Plant Based Ground
                </a>{" "}
                has 24 grams of protein and soy free has never tasted so
                delectable. Toss it in a taco or cook it into a yummy sauce—this
                plant based protein is your oyster.
              </p>
            </li>
            <li>
              <H4 headingText1="Where to purchase?" />
              <p>
                Easy-PEAsy. You’ll find our full lineup of plant based products
                in the refrigerated and frozen aisles of select supermarkets.
                Happy hunting.
              </p>
            </li>
            <li>
              <H4 headingText1="Is eating less meat good for the environment?" />
              <p>
                There are many benefits of reducing meat consumption, and one of
                them is helping the environment. Currently, the cows, pigs and
                chickens we raise generate the same greenhouse gas emissions as
                every car, truck and automobile combined. So yes. Adopting “meat
                consciousness” as a society is necessary to reduce our carbon
                footprint for future generations.
              </p>
            </li>
            <li>
              <H4 headingText1="Plant based vs. vegan diet: What’s the difference?" />
              <p>
                A plant based diet is one that relies most heavily on plant
                foods for nutrition. It doesn’t necessarily exclude all animals
                and their biproducts, like a vegan diet does.
              </p>
            </li>
          </ul>
          <CenteredCTA
            bgImage="/illustrated-background.svg"
            badgeSrc="/default.svg"
            headingText2="Still Have Questions?"
            headingColor2="#006B2D"
            bodyColor="#006B2D"
            bodyText="No worries! Send us a line and we’ll help you out."
            buttonText="Contact Us"
            buttonLink="/contact-us/"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
          />
        </Layout>
      </React.Fragment>
    )
  }
}
